.guardiansContainer {
  object-fit: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0% 0% 0% 0%;
  max-width: 1080px;
  min-height: 100vh;
  overflow: hidden;
}

.guardians {
  width: 100%;
  object-fit: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-radius: 25px;
  overflow: hidden;
}


@media only screen and (max-width: 560px) {
  .guardians {
    width: 100%;
  }

  .guardiansContainer {
    min-height: 30vh;
    max-width: 100vw;
    margin: 0% 0% 0% 0%;
    padding: 0% 0% 0% 0%;
  }
}
