.banner {
  object-fit: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 10% 0% 0% 0%;
  overflow: hidden;
  background-color: black;
}

.bannerContainer {
  width: 100%;
  object-fit: cover;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.collectForeverBannerContainer {
  background-color: #ffffff;
  padding-top: 5%;
}

.bannerImage {
  width: 60%;
}


@media only screen and (max-width: 560px) {
  .bannerImage {
    width: 90vw;
  }

  .collectForeverBannerImage {
    width: 95vw;
    padding: 8% 0% 8% 0%;
  }

  .banner {
    margin: 0% 0% 0% 0%;
    padding: 18% 0% 0% 0%;
  }
}
