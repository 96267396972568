/*1st background1.png*/
/*.background1 {
  object-fit: fill;
  width: 203%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: -50px 0px -3900px 0px;
}*/

.background {
  perspective: 1px;
}

.background1 {
  width: 100%;
  height: 800px;  
  object-fit: cover;
  object-position: top;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin: 0px 0px 0px 0px;
  position: absolute;
  z-index: -1;
  transform: translateZ(-1px);
  overflow: hidden;
}

.background2 {
  width: 0%;
  object-fit: cover;
  object-position: top;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  margin: 0px 0px 0px 0px;
  position: absolute;
  z-index: -1;
  transform: translateZ(-1px);
  overflow: hidden;
  width: 0%;
}

.background3 {
  width: 0%;
  object-fit: cover;
  object-position: top;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  margin: 0px 0px 0px 0px;
  position: absolute;
  z-index: -1;
  transform: translateZ(-1px);
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .background1 {
    height: 160vh;
  }

  .background2 {
    width: 100%;
    display: block;
  }

  .background3 {
    width: 100%;
    display: block;
  }
}
