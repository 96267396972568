a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: none; }
a:active { text-decoration: none; }

.navbar {
  display: flex;
  justify-content: center;
  padding: 0% 0% 0% 0%;
  margin: 0% 0% 0% 0%;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  position: fixed;
  z-index: 10000;
}

.navbarContainer {
  display: flex;
  width: 100%;
  max-width: 1920px;
  
  align-items: flex-end;
  margin: 0px 0px 0px 0px;
}

.navbarLeft {
  width: 100%;
  display: flex;
  flex-direction: row;
}



.navbarRight {
  display: flex;
  align-items: right;
  justify-content: right;
  float: right;
  margin: 0% 0% .5% 0%;
}

.navbarLogoImage {
  width: 15%;
  padding: 8px 0px 3px 8px;
}





.navbarIcon {
  width: 4%;
  padding: 0% 2% 0% 0%;
  cursor: pointer;
  align-items: right;
  justify-content: right;
  float: right;
}


.navbarMobileButton {
  display: none;
}
.MobileDN {
  display: none;
}
.navbarMobile {
  display: none;
}

.twitterIcon {
  padding: 0% 1% 0% 0%;
  margin: -1% 0% 0% 0%;
  width: 6%;
}

.discordIcon {
  padding-top: .25%;
  width: 4.8%;
}


@media only screen and (max-width: 560px) {
  .navbar {
    max-width: 100vw;
    margin: 0% 0% 0% 0%;
    padding: 0% 0% 0% 0%;
  }
  

  .navbarIcon {
    width: 8%;
  }

  .navbarSocialsContainer {
    margin: -12% 0% 0% 0%;
    padding: 0% 0% 0% 0%;
  }

  .navbarLogoImage {
    width: 100%;
    padding: 0% 0% 0% 15%;
    margin: 10% 0% 0% 0%;
  }

  .twitterIcon {
    width: 11.5%;
    margin-top: -2%;
  }

  .discordIcon {
    width: 9%;
  }

  /* .linkedinIcon {
    padding-top: 2%;
  }

  .instagramIcon {
    padding-top: 2%;
  } */
}



